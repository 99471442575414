import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Seo } from 'components/seo'
import { HeroImageTitle } from 'components/new/hero'
import Layout from 'components/layout'
import Content from 'components/new/content'

const ProductLines = ({ data: { allSanityBrand, heroImage } }) => {
  const allSanityBrandNodes = allSanityBrand.nodes
  return (
    <Layout>
      <Seo
        breadcrumbs={[
          {
            name: 'Hutson Inc',
            displayName: 'Home',
            link: '/',
          },
          {
            name: 'Product Lines',
            link: '/product-lines/',
          },
        ]}
        description='Learn more about the additional product lines that we carry, including Landoll, Billy Goat, Nuhn, Amco, and so many more.'
        keywords={['brands', 'products', 'product lines', 'short lines']}
        title='Product Lines'
      />

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Product Lines'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <Grid>
          {allSanityBrandNodes.map(line => (
            <Column key={line.handle}>
              <LineLink to={line.slug}>
                <div className='line-image-container'>
                  <GatsbyImage
                    image={line.logo.asset.gatsbyImageData}
                    alt={line.name}
                    objectFit='contain'
                    objectPosition='50% 50%'
                    style={{ width: '100%' }}
                  />
                </div>
                <LineTitle>{line.name}</LineTitle>
              </LineLink>
            </Column>
          ))}
        </Grid>
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: 600px) {
    ${clearfix}
  }
`

const Column = styled.div`
  @media (max-width: 399px) {
    ${column('1/2', 48)}
    margin: 0 0 48px;
  }

  @media (min-width: 400px) {
    ${column('1/2', 60)}
    margin: 0 0 60px;
  }

  @media (min-width: 600px) {
    ${column('1/3', 60)}
  }

  @media (min-width: 800px) {
    ${column('1/4', 60)}
  }

  @media (min-width: 1000px) {
    ${column('1/5', 60)}
  }

  @media (min-width: 1200px) {
    ${column('1/6', 60)}
  }
`

const LineLink = styled(Link)`
  color: #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  text-decoration: none;
  width: 100%;

  :hover,
  :focus {
    text-decoration: underline;
  }

  .line-image-container {
    display: flex;
    align-content: center;
    flex-grow: 2;
    margin-bottom: 15px;
    position: relative;
  }
`

const LineTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-align: center;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "product-lines/product-lines-hero.jpg" }) {
      ...FullWidthImage
    }
    allSanityBrand(filter: { showProductLine: { eq: true } }, sort: { fields: name, order: ASC }) {
      nodes {
        description
        handle
        logo {
          asset {
            gatsbyImageData(height: 140)
          }
        }
        name
        products
        slug
      }
    }
  }
`

export default ProductLines
